import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { read, utils } from "xlsx";

function MyDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      props.setFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = read(bstr, { type: "binary" });

        var sheetsData = wb.SheetNames.map((name) => {
          const sheet = wb.Sheets[name];
          let data = utils.sheet_to_json(sheet, { header: 1 });

          // Find the maximum length among all rows
          let maxLength = Math.max(...data.map((arr) => arr.length));

          // Pad shorter rows with null
          data = data.map((arr) => [
            ...arr,
            ...Array(maxLength - arr.length).fill(""),
          ]);

          return { name, data };
        });

        sheetsData = sheetsData.map((sheet) => {
          sheet.data = sheet.data
            .map((row) => {
              //if every element is empty, remove row
              if (row.every((element) => element === "")) {
                return null;
              }
              return row;
            })
            .filter((row) => row !== null);
          return sheet;
        });

        props.setData(sheetsData[0].data);
        props.setSheets(
          sheetsData.map((sheet, i) => {
            return { label: sheet.name, value: i };
          })
        );
        props.setStep(1);
        props.setChooseQuestionsDone(true);
        props.setUploadedData(sheetsData);
      };

      reader.readAsBinaryString(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="flex flex-col">
      <div
        {...getRootProps()}
        className="relative flex flex-col items-center justify-center m-auto border-4 border-dashed border-grey-100 rounded-md py-48 px-60 transition-colors duration-200 ease-in-out mt-22"
      >
        <input
          {...getInputProps()}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
        <div>
          <img src="../upload.svg" alt="upload" className="m-auto mb-4" />

          <p className="text-lg text-euragreylight text-lg">
            Download or drag and drop the file
          </p>
        </div>
      </div>
    </div>
  );
}

export default MyDropzone;
