import MyDropzone from "../components/MyDropzone";
import React, { useEffect, useState } from "react";
import CommonFeatures from "../components/DataGrid";
import Grid from "../components/DataGrid";
import Select from "react-select";
import AutoCompleteInput from "../components/AutoCompleteInput";
import FileUploadComponent from "../components/FileUploadComponent";
import { Menu } from "../components/Menu";
import { Trail } from "../components/Trail";
import e from "cors";
import { useHistory } from "react-router-dom";
import { StepComponent } from "../components/StepComponent";
import { Validate } from "../components/Validate";
import { useParams } from "react-router-dom";

const HomePage = (props) => {
  const { stepId } = useParams();

  var [data, setData] = useState([]);
  var [hidden, setHidden] = useState(false);
  var [step, setStep] = useState(Number(stepId) + 1);
  var [columns, setColumns] = useState([]);
  var [rows, setRows] = useState([]);
  var [questionColumn, setQuestionColumn] = useState(-1);
  var [answerColumn, setAnswerColumn] = useState(-2);
  var [sheets, setSheets] = useState(null);
  var [selectedSheet, setSelectedSheet] = useState(0);
  var [uploadedData, setUploadedData] = useState(null);
  var [questions, setQuestions] = useState([]);
  var [file, setFile] = useState(null);
  var [invertRows, setInvertRows] = useState(false);
  var [token, setToken] = useState(null);
  var [companiesSelected, setCompaniesSelected] = useState([]);
  var [fundsSelected, setFundsSelected] = useState([]);
  const [sendProcess, setSendProcess] = useState(false);
  const [processSent, setProcessSent] = useState(false);

  const [nbTokens, setNbTokens] = useState(0);
  const [nbQuestionsProcessed, setNbQuestionsProcessed] = useState(0);
  const [executionTime, setExecutionTime] = useState(0);

  const [nextStepAvailable, setNextStepAvailable] = useState(false);

  const [scope, setScope] = useState("Companies");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected
        ? "#008C8D"
        : state.isFocused
        ? "#E5E5E5"
        : "white",
    }),
  };

  var history = useHistory();

  var [questionLabel, setQuestionLabel] = useState({
    value: 0,
    label: "Column 1",
  });
  var [answerLabel, setAnswerLabel] = useState({
    value: 0,
    label: "Column 1",
  });

  var steps = [
    "ESG Engine > Answer Automatically ESG LP survey",
    "ESG Engine > Answering",
    "ESG Engine > Answering",
    "ESG Engine > Scope",
    "ESG Engine > Answering",
    "ESG Engine > Results",
  ];

  var [questionsDone, setQuestionsDone] = useState(false);
  var [answersDone, setAnswersDone] = useState(false);
  var [verifyDone, setVerifyDone] = useState(false);
  var [scopeDone, setScopeDone] = useState(false);
  var [chooseQuestionsDone, setChooseQuestionsDone] = useState(false);
  var [chooseScopeDone, setChooseScopeDone] = useState(false);
  var [runAnalysisDone, setRunAnalysisDone] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  var [estimatedTime, setEstimatedTime] = useState(null);

  // To navigate to a different step
  const goToStep = (stepNumber) => {
    history.push(`/step/${stepNumber + 1}`);
    setStep(stepNumber);
  };

  useEffect(() => {
    setStep(Number(stepId) - 1);
  }, [stepId]);

  useEffect(() => {
    if (
      (companiesSelected.length > 0 && scope === "Companies") ||
      (fundsSelected.length > 0 && scope === "Funds")
    )
      setNextStepAvailable(true);
    else setNextStepAvailable(false);
  }, [companiesSelected, fundsSelected]);

  return (
    <div className="flex flex-col h-screen w-screen font-regular">
      <Menu
        current={props.current}
        setCurrent={props.setCurrent}
        user={props.user}
      />
      <div className="flex flex-grow overflow-auto">
        {false && (
          <button
            className="bg-eurablue mr-auto flex flex-col  overflow-auto shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in-out w-144"
            onClick={() => {
              props.setCurrent("history");
              history.push("/history");
            }}
          >
            <p className="w-full mx-auto font-semibold text-lg text-white py-6">
              Uploaded today
            </p>
            <div className="w-full flex flex-col">
              {props.executionsToday.map((execution, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col mx-auto border-t border-grey-300 py-6 w-full"
                  >
                    <div className="flex justify-between items-center w-full">
                      <p className="text-white font-medium whitespace-nowrap w-full text-sm">
                        {execution.sheet_name.length > 28
                          ? execution.sheet_name.substring(0, 28) + "..."
                          : execution.sheet_name}
                      </p>
                      <p className="text-grey-500 mr-10">
                        {new Date(execution.timestamp)
                          .getHours()
                          .toString()
                          .padStart(2, "0") +
                          ":" +
                          new Date(execution.timestamp)
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </button>
        )}
        <div className="flex flex-col bg-gray flex-grow w-full mx-auto ml-26 mt-28">
          <div className="flex flex-col w-full">
            <div className="flex flex-col ml-24">
              <p className="text-euratextgrey text-lg">ESG Engine</p>
              <p className="flex text-eurablue text-xl font-bold">
                {step <= 5 ? steps[step] : steps[5]}
              </p>
            </div>
            <div className="flex mt-8">
              <div className="ml-24 mr-12">
                <Trail
                  step={step}
                  setStep={goToStep}
                  questionsDone={questionsDone}
                  setQuestionsDone={setQuestionsDone}
                  answersDone={answersDone}
                  setAnswersDone={setAnswersDone}
                  verifyDone={verifyDone}
                  setVerifyDone={setVerifyDone}
                  scopeDone={scopeDone}
                  setScopeDone={setScopeDone}
                  chooseQuestionsDone={chooseQuestionsDone}
                  setChooseScopeDone={setChooseScopeDone}
                  chooseScopeDone={chooseScopeDone}
                  setRunAnalysisDone={setRunAnalysisDone}
                  runAnalysisDone={runAnalysisDone}
                />
              </div>
              <div className="flex flex-col bg-white w-8/12 2xl:w-9/12 rounded-xl box">
                <StepComponent
                  step={step}
                  setStep={goToStep}
                  setHidden={setHidden}
                  setQuestionsDone={setQuestionsDone}
                  setAnswersDone={setAnswersDone}
                  setVerifyDone={setVerifyDone}
                  setScopeDone={setScopeDone}
                  data={data}
                  setData={setData}
                  columns={columns}
                  setColumns={setColumns}
                  rows={rows}
                  setRows={setRows}
                  questionColumn={questionColumn}
                  setQuestionColumn={setQuestionColumn}
                  answerColumn={answerColumn}
                  setAnswerColumn={setAnswerColumn}
                  sheets={sheets}
                  setSheets={setSheets}
                  selectedSheet={selectedSheet}
                  setSelectedSheet={setSelectedSheet}
                  uploadedData={uploadedData}
                  setUploadedData={setUploadedData}
                  questions={questions}
                  setQuestions={setQuestions}
                  file={file}
                  setFile={setFile}
                  invertRows={invertRows}
                  setInvertRows={setInvertRows}
                  token={token}
                  setToken={setToken}
                  companiesSelected={companiesSelected}
                  setCompaniesSelected={setCompaniesSelected}
                  fundsSelected={fundsSelected}
                  setFundsSelected={setFundsSelected}
                  questionLabel={questionLabel}
                  setQuestionLabel={setQuestionLabel}
                  answerLabel={answerLabel}
                  setAnswerLabel={setAnswerLabel}
                  customStyles={customStyles}
                  funds={props.funds}
                  companies={props.companies}
                  sendProcess={sendProcess}
                  processSent={processSent}
                  setProcessSent={setProcessSent}
                  setChooseQuestionsDone={setChooseQuestionsDone}
                  chooseQuestionsDone={chooseQuestionsDone}
                  setNextStepAvailable={setNextStepAvailable}
                  setRunAnalysisDone={setRunAnalysisDone}
                  runAnalysisDone={runAnalysisDone}
                  uploadProgress={uploadProgress}
                  estimatedTime={estimatedTime}
                  setEstimatedTime={setEstimatedTime}
                  setUploadProgress={setUploadProgress}
                  scope={scope}
                  setScope={setScope}
                  setNbTokens={setNbTokens}
                  nbTokens={nbTokens}
                  setNbQuestionsProcessed={setNbQuestionsProcessed}
                  nbQuestionsProcessed={nbQuestionsProcessed}
                  executionTime={executionTime}
                  setExecutionTime={setExecutionTime}
                />
                {step >= 1 && (
                  <Validate
                    setStep={goToStep}
                    step={step}
                    setQuestionsDone={setQuestionsDone}
                    questionsDone={questionsDone}
                    setAnswersDone={setAnswersDone}
                    setVerifyDone={setVerifyDone}
                    setSendProcess={setSendProcess}
                    sendProcess={sendProcess}
                    processSent={processSent}
                    setProcessSent={setProcessSent}
                    setChooseQuestionsDone={setChooseQuestionsDone}
                    setChooseScopeDone={setChooseScopeDone}
                    nextStepAvailable={nextStepAvailable}
                    setNextStepAvailable={setNextStepAvailable}
                    token={token}
                    setRunAnalysisDone={setRunAnalysisDone}
                    runAnalysisDone={runAnalysisDone}
                    uploadedData={uploadedData}
                    setData={setData}
                    goToStep={goToStep}
                    uploadProgress={uploadProgress}
                    estimatedTime={estimatedTime}
                    setEstimatedTime={setEstimatedTime}
                    setUploadProgress={setUploadProgress}
                    setCompaniesSelected={setCompaniesSelected}
                    setFundsSelected={setFundsSelected}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
