import React, { useEffect } from "react";
import { useState } from "react";

function Table(props) {
  const unselectAll = () => {
    props.setQuestions(
      props.questions.map((question) => {
        return { ...question, isChecked: false };
      })
    );
  };

  const selectAll = () => {
    props.setQuestions(
      props.questions.map((question) => {
        return { ...question, isChecked: true };
      })
    );
  };

  const handleCheckChange = (id) => {
    props.setQuestions(
      props.questions.map((question) =>
        question.id === id
          ? { ...question, isChecked: !question.isChecked }
          : question
      )
    );
  };
  // Render the UI for your table

  return (
    <div className="flex flex-col w-full h-full rounded-md px-3">
      <div className="flex flex-grow overflow-scroll h-full w-full">
        <div className="flex flex-col w-full">
          <p className={"text-euragreylight mt-8 font-medium ml-3.5 text-lg"}>
            Please uncheck the ones you don't want to include
          </p>
          <div className="flex bg-euralight text-euratextgrey rounded-md p-4 font-bold">
            <p>
              {props.questions.filter((question) => question.isChecked).length}{" "}
              questions selected
            </p>
            <div className="ml-auto">
              <p className="font-light text-euratable shadow">
                <button
                  onClick={() => {
                    selectAll();
                  }}
                >
                  <span className="underline">select all</span>
                </button>
                /{" "}
                <button
                  onClick={() => {
                    unselectAll();
                  }}
                >
                  <span className="underline">unselect all</span>
                </button>
              </p>
            </div>
          </div>
          {props.questions.map((question, index) => {
            const checkboxId = `checkbox-${question.id}`;
            return (
              <div className="flex flex-col px-4 py-2">
                <div key={index} className="flex">
                  <div>
                    <input
                      id={checkboxId}
                      type="checkbox"
                      checked={question.isChecked}
                      onChange={() => {
                        handleCheckChange(question.id);
                      }}
                    />
                    <label
                      htmlFor={checkboxId}
                      className="checkbox-label"
                    ></label>
                  </div>
                  <p className="text-euratable ml-4 mb-2">{question.text}</p>
                </div>
                <div className="w-full h-0.25 bg-euraborder"></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Table;
