import React, { useState, useEffect } from "react";
import AutoCompleteInput from "./AutoCompleteInput";
import axios from "axios";

export function ScopeSelectorCompanies(props) {
  const [chooseByFunds, setChooseByFunds] = useState(false);

  useEffect(() => {
    // Fetch companies when a fund is selected
    async function fetchCompaniesByFund() {
      var fund = props.fundsSelected[props.fundsSelected.length - 1];
      if (fund === undefined) return;
      fund = fund.title;

      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/companies?fund_name=${fund}`
        );
        if (response.data && Array.isArray(response.data)) {
          const companiesFromFund = response.data;
          //foreach of companies from fund add it to the list if it is not already in the list
          companiesFromFund.forEach((element) => {
            element = { title: element };
            //setCompaniesSelected
            if (!props.companiesSelected.includes(element)) {
              props.setCompaniesSelected((value) => [...value, element]);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching companies by fund:", error);
      }
    }

    fetchCompaniesByFund();
  }, [props.fundsSelected]); // Run this effect whenever the selected fund changes

  return (
    <div>
      <div className="flex mb-4">
        <input
          id={1}
          type="checkbox"
          checked={chooseByFunds}
          onChange={() => {
            setChooseByFunds(!chooseByFunds);
          }}
        />
        <label htmlFor={1} className="checkbox-label"></label>
        <p className="ml-3 text-euratable font-normal">
          Select companies by fund
        </p>
      </div>
      <div className="flex flex-col">
        {chooseByFunds && (
          <div className="flex flex-col mb-5">
            <p className="text-euratable font-normal">Which fund?</p>
            <AutoCompleteInput
              data={props.funds.map((fund) => {
                return { title: fund.fundName, short_name: fund.shortName };
              })}
              setSelected={props.setFundsSelected}
              value={props.fundsSelected}
            />
          </div>
        )}
        <div className="flex flex-col">
          <p className="text-euratable font-normal">Selected Companies</p>
          <AutoCompleteInput
            data={props.companies}
            setSelected={props.setCompaniesSelected}
            value={props.companiesSelected}
          />
        </div>
      </div>
    </div>
  );
}
