import { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import HistoryPage from "./page/HistoryPage";
import HomePage from "./page/HomePage";
import axios from "axios";
import { useHistory } from "react-router-dom";

function App() {
  var [executions, setExecutions] = useState([]);
  var [executionsToday, setExecutionsToday] = useState([]);
  var current_path = window.location.pathname;
  current_path = current_path.substring(1, current_path.length);

  var [current, setCurrent] = useState(current_path);
  var [companies, setCompanies] = useState([]);
  var [funds, setFunds] = useState([]);

  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/executions")
      .then((response) => {
        setExecutions(response.data);
        // filter executions by timestamp of today
        setExecutionsToday(
          response.data
            .filter((execution) => {
              return execution.timestamp > new Date().setHours(0, 0, 0, 0);
            })
            .sort((a, b) => {
              return b.timestamp - a.timestamp;
            })
        );
      });
    axios.get(process.env.REACT_APP_API_URL + "/companies").then((response) => {
      setCompanies(
        response.data.map((company) => {
          return { title: company };
        })
      );
    });
    axios.get(process.env.REACT_APP_API_URL + "/funds").then((response) => {
      setFunds(
        response.data.map((fund) => {
          return fund;
        })
      );
    });
  }, []);

  const history = useHistory();

  useEffect(() => {
    if (window.location.pathname === "/") {
      history.push("/step/1");
    }
  }, []);

 

  return (
    <div>
      <Switch>
        <Route path="/step/:stepId">
          <HomePage
            executions={executions}
            executionsToday={executionsToday}
            current={current}
            setCurrent={setCurrent}
            companies={companies}
            funds={funds}
            user={user}
          />
        </Route>
      </Switch>
    </div>
  );
}
export default App;
