export const Validate = (props) => {
  var steps_title = [
    "",
    "Validate",
    "Validate Questions",
    "Validate & Run Analysis",
    "View Results",
    "Download Results",
  ];

  return (
    <div className="flex mx-auto p-8 shadow shadow-2xl w-full h-1/6">
      <div className="flex m-auto">
        <button
          className="border border-euragreen bg-white text-white py-2 px-4 rounded w-32 h-12"
          onClick={() => {
            props.setStep(props.step - 1);
            if (props.step === 1) {
              props.setChooseQuestionsDone(false);
            } else if (props.step === 2) {
              props.setNextStepAvailable(false);
              props.setQuestionsDone(false);
              props.setAnswersDone(false);
            } else if (props.step === 3) {
              props.setNextStepAvailable(true);
              props.setVerifyDone(false);
            } else if (props.step === 4) {
              props.setSendProcess(false);
              props.setProcessSent(false);
              props.setChooseScopeDone(false);
              props.setEstimatedTime(null);
              props.setUploadProgress(0);
              props.setCompaniesSelected([]);
              props.setFundsSelected([]);
            }
          }}
        >
          <p className="text-euragreen">Back</p>
        </button>
        <button
          className={
            "ml-10 text-white py-2 px-4 rounded h-12 min-w-32 " +
            (props.nextStepAvailable ? "bg-euragreen" : "bg-eurahidden")
          }
          onClick={() => {
            if (!props.nextStepAvailable) return;
            if (props.step === 5) {
              window.open(
                process.env.REACT_APP_API_URL + "/download/" + props.token,
                "_blank"
              );
            } else {
              props.setStep(props.step + 1);
              if (props.step === 1) {
                props.setQuestionsDone(true);
                props.setAnswersDone(true);
              } else if (props.step === 2) {
                props.setNextStepAvailable(false);
                props.setVerifyDone(true);
              } else if (props.step === 3) {
                props.setSendProcess(true);
                props.setChooseScopeDone(true);
                props.setNextStepAvailable(false);
              } else if (props.step === 4) {
                props.setRunAnalysisDone(true);
              }
            }
          }}
        >
          <p className="mx-auto text-white whitespace-nowrap">
            {steps_title[props.step]}
          </p>
        </button>
        {props.step === 5 && (
          <button
            className={
              "ml-10 text-white py-2 px-4 rounded h-12 min-w-32 " +
              (props.nextStepAvailable ? "bg-euragreen" : "bg-eurahidden")
            }
            onClick={() => {
              props.setStep(3);
              props.setData(props.uploadedData[0].data);
              props.setSendProcess(false);
              props.setProcessSent(false);
              props.setChooseScopeDone(false);
              props.setEstimatedTime(null);
              props.setUploadProgress(0);
              props.setCompaniesSelected([]);
              props.setFundsSelected([]);
            }}
          >
            <p className="mx-auto text-white whitespace-nowrap">
              Run again on new Scope
            </p>
          </button>
        )}
      </div>
    </div>
  );
};
