import React, { useState, useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { read, utils } from "xlsx";
import { useRef } from "react";

function LinearProgressWithLabel(props) {
  return (
    <Box className="flex mx-auto items-center">
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function FileUploadComponent(props) {
  const [requestEnded, setRequestEnded] = useState(false);
  const [socketEnded, setSocketEnded] = useState(false);

  // using useRef to store the socket instance
  const socketRef = useRef(null);

  const onClickHandler = async () => {
    const data = new FormData();
    data.append("file", props.file);
    //append questions
    data.append(
      "questions",
      JSON.stringify(props.questions.filter((question) => question.isChecked))
    );
    data.append("answer_column", props.answerColumn);
    data.append("invert_rows", props.invertRows);
    data.append("scope", props.scope);
    //send array companiesSelected
    data.append(
      "companies",
      JSON.stringify(props.companiesSelected.map((company) => company.title))
    );
    //send array fundsSelected
    data.append(
      "funds",
      JSON.stringify(props.fundsSelected.map((fund) => fund.title))
    );
    data.append("selected_sheet", props.selectedSheet);

    var response = await axios.post(
      process.env.REACT_APP_API_URL + "/file/upload",
      data
    );

    props.setToken(response.data.token);
    props.setData(response.data.final_file);
    props.setNbTokens(response.data.nb_tokens);
    props.setNbQuestionsProcessed(response.data.nb_processed);
    props.setExecutionTime(response.data.execution_time);
    props.setUploadProgress(100);
    props.setNextStepAvailable(true);

    //disconnect socket
    socketRef.current && socketRef.current.disconnect();
  };

  useEffect(() => {
    setSocketEnded(false);
    setRequestEnded(false);
    socketRef.current = io(process.env.REACT_APP_API_URL);

    const onFileUploadProgress = (data) => {
      data.progress = parseInt(data.progress);
      props.setUploadProgress(data.progress);
      var time_left = Math.round(data.time_left);
      var minutes = Math.floor(time_left / 60);
      var seconds = time_left - minutes * 60;
      props.setEstimatedTime(minutes + " minutes " + seconds + " seconds");
      if (data.progress === 100) {
        setSocketEnded(true);
        socketRef.current.disconnect();
      }
    };

    socketRef.current.on("fileUploadProgress", onFileUploadProgress);

    return () => {
      if (socketRef.current) {
        socketRef.current.off("fileUploadProgress", onFileUploadProgress);
      }
    };
  }, [props.step]);

  useEffect(() => {
    if (props.sendProcess && !props.processSent) {
      props.setProcessSent(true);
      props.setStep(4);
      onClickHandler();
    }
  }, [props.sendProcess]);

  return (
    <div className="flex flex-col">
      <div className="mx-auto">
        <div className={"mx-auto mt-36"}>
          {props.step === 4 && (
            <div>
              <p className="mx-auto mb-3">
                The file is currently processing, please wait.
              </p>
              <LinearProgressWithLabel value={props.uploadProgress} />{" "}
              {props.estimatedTime && (
                <p className="text-eurablue text-bold mt-3">
                  Estimated time left: {props.estimatedTime}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FileUploadComponent;
