import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";

/*

const generateStyles = (companies, answerColumn) => {
  return companies
    .map(
      (company, i) => `
    [aria-colindex="${answerColumn + 2 + i}"] {
      background-color: #F2F4F7 !important;
      color: black !important;
      border-left: 1px solid black !important;
      border-right: 1px solid black !important;
      border-bottom: 1px solid black !important;
    }
    [role="columnheader"][aria-colindex="${answerColumn + 2 + i}"] {
      background-color: #002E54 !important;
      color: white !important;
      border-left: 1px solid black !important;
      border-right: 1px solid black !important;
      border-bottom: 1px solid black !important;
    }
  `
    )
    .join("");
};

*/

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img
          src="../arrow.svg"
          alt="custom-indicator"
          style={{ width: "16px", height: "16px" }}
        />
      </components.DropdownIndicator>
    )
  );
};

const CustomPlaceholder = (props) => (
  <components.Placeholder {...props}>
    <div className="flex">
      <img
        src="../loop.svg"
        alt="custom-placeholder"
        style={{
          width: "16px",
          height: "16px",
          marginRight: "10px",
          marginTop: "2px",
        }}
      />
      {props.children}
    </div>
  </components.Placeholder>
);

function RowNumberFormatter(props) {
  return <>{1}</>;
}

export default function Grid(props) {
  //calculate the height of the screen to set the height of the grid
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const invertData = (data) => {
    //the first column becomes the first row
    var newData = [];
    for (var i = 0; i < data[0].length; i++) {
      newData.push([]);
    }
    for (i = 0; i < data.length; i++) {
      for (var j = 0; j < data[i].length; j++) {
        newData[j].push(data[i][j]);
      }
    }

    // Find the maximum length among all rows
    let maxLength = Math.max(...newData.map((arr) => arr.length));

    // Pad shorter rows with null
    newData = newData.map((arr) => [
      ...arr,
      ...Array(maxLength - arr.length).fill(""),
    ]);

    return newData;
  };

  useEffect(() => {
    if (
      !props.data ||
      props.data.length === 0 ||
      props.data[0].sheet !== undefined
    ) {
      return;
    }

    var columns = [];
    if (props.step !== 5) {
      for (var i = 0; i < props.data[0].length; i++) {
        columns.push({
          key: i,
          name: "Column " + (i + 1),
          resizable: true,
          width: 150,
        });
      }
    } else {
      for (var i = 0; i < props.data[0].length; i++) {
        columns.push({
          key: i,
          name: props.data[0][i],
          resizable: true,
          width: 150,
        });
      }
    }
    props.setColumns(columns);

    var rows = [];
    for (var i = props.step !== 5 ? 0 : 1; i < props.data.length; i++) {
      var row = {};
      for (var j = 0; j < props.data[i].length; j++) {
        row[j] = props.data[i][j];
      }
      // Add the row number to the beginning of the row
      var new_row = row;
      new_row["rowNumber"] = i + 1;
      rows.push(new_row);
    }

    props.setRows(rows);
  }, [props.data]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#002F54",
      backgroundColor: state.isSelected
        ? "#E5E5E5"
        : state.isFocused
        ? "#E5E5E5"
        : "white",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#002E54", // set color to blue
      };
    },
  };

  const [sheetSelected, setSheetSelected] = useState(false);
  const [questionsSelected, setQuestionsSelected] = useState(false);
  const [answersSelected, setAnswersSelected] = useState(false);

  useEffect(() => {
    if (sheetSelected && questionsSelected && answersSelected)
      props.setNextStepAvailable(true);
  }, [sheetSelected, questionsSelected, answersSelected]);

  return (
    <div className="flex flex-col mx-auto w-[97%] h-full">
      {props.step !== 5 && props.questionColumn !== -1 && (
        <style>
          {`
                [aria-colindex="${props.questionColumn + 2}"] {
                    background-color: #E5E5E5 !important;
                    color: black !important;
                    border-left: 1px solid black !important;
                    border-right: 1px solid black !important;
                    border-bottom: 1px solid black !important;
                  

                    /* Add more styles as needed */
                }
                [role="columnheader"][aria-colindex="${
                  props.questionColumn + 2
                }"] {
                  background-color: #008C8D !important;
                  color: white !important;
                  border-left: 1px solid black !important;
                  border-right: 1px solid black !important;
                  border-bottom: 1px solid black !important;
                }
                [aria-colindex="${props.answerColumn + 2}"] {
                  background-color: #F2F4F7 !important;
                  color: black !important;
                  border-left: 1px solid black !important;
                  border-right: 1px solid black !important;
                  border-bottom: 1px solid black !important;
                

                  /* Add more styles as needed */
              }
              [role="columnheader"][aria-colindex="${props.answerColumn + 2}"] {
                background-color: #002E54 !important;
                color: white !important;
                border-left: 1px solid black !important;
                border-right: 1px solid black !important;
                border-bottom: 1px solid black !important;
              }
                `}
        </style>
      )}
      {props.step === 1 && (
        <div className="h-16 mt-8 flex items-center justify-between">
          <div className="flex">
            <div className="flex flex-col mr-7">
              <div className="select-container">
                <Select
                  placeholder="Select tab to analyse..."
                  options={props.sheets}
                  onChange={(e) => {
                    setSheetSelected(true);
                    props.setData(props.uploadedData[e.value].data);
                    props.setSelectedSheet(e.value);
                  }}
                  components={{
                    Placeholder: CustomPlaceholder,
                    DropdownIndicator,
                  }}
                  styles={customStyles}
                />
              </div>
            </div>
            <button
              className="mr-7"
              onClick={() => {
                //invert rows
                props.setData(invertData(props.data));
                props.setInvertRows(!props.invertRows);
              }}
            >
              <img src="../invert.svg"></img>
            </button>
          </div>
          <div className="select-container ml-5">
            <Select
              placeholder="Select where the questions are..."
              options={props.columns.map((column, index) => {
                return { value: index, label: column.name };
              })}
              components={{
                Placeholder: CustomPlaceholder,
                DropdownIndicator,
              }}
              styles={customStyles}
              onChange={(e) => {
                setQuestionsSelected(true);
                props.setQuestionColumn(e.value);
                props.setQuestionLabel(e);
                var i = 0;
                props.setQuestions([]);
                props.data.forEach((row) => {
                  props.setQuestions((questions) => [
                    ...questions,
                    {
                      id: i++,
                      text: row[e.value],
                      isChecked: true,
                    },
                  ]);
                });
              }}
            />
          </div>
          <div className="select-container ml-5">
            <Select
              placeholder="Select column to push answers..."
              options={props.columns.map((column, index) => {
                return { value: index, label: column.name };
              })}
              components={{
                Placeholder: CustomPlaceholder,
                DropdownIndicator,
              }}
              styles={customStyles}
              onChange={(e) => {
                setAnswersSelected(true);
                props.setAnswerColumn(e.value);
                props.setAnswerLabel(e);
              }}
            />
          </div>
        </div>
      )}
      {(sheetSelected || props.step >= 5) && (
        <div className="flex flex-col flex-grow">
          <DataGrid
            columns={[
              {
                key: "rowNumber",
                name: "", // Empty header
                formatter: RowNumberFormatter,
                width: 10, // you can adjust the width as necessary
                resizable: false,
              },
            ].concat(props.columns)}
            rows={props.rows}
            className={
              "rounded-xl mt-auto scrollbar-custom " +
              (props.step === 5 ? "h-100 2xl:h-144" : "h-100 2xl:h-140")
            }
          />
          {props.step === 5 && (
            <div className="flex my-auto ml-2">
              <div className="flex text-eurablue">
                <p>Total number of tokens consumed:</p>
                <p className="font-bold ml-2">{props.nbTokens}</p>
              </div>
              <div className="flex text-eurablue">
                <p className="font-bold ml-2">/</p>
              </div>
              <div className="flex text-eurablue ml-1">
                <p>Number of questions answered:</p>
                <p className="font-bold ml-2">
                  {props.nbQuestionsProcessed} of{" "}
                  {props.questions.filter((question) => question.isChecked)
                    .length *
                    (props.scope === "Companies"
                      ? props.companiesSelected.length
                      : props.fundsSelected.length)}
                </p>
              </div>
              <div className="flex text-eurablue">
                <p className="font-bold ml-2">/</p>
              </div>
              <div className="flex text-eurablue ml-1">
                <p>Total execution time:</p>
                <p className="font-bold ml-2">{props.executionTime} seconds</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
