import React, { useEffect } from "react";
import { useState } from "react";

export function Round(props) {
  var [color, setColor] = useState("eurahidden");
  var [colorBorder, setColorBorder] = useState("white");

  useEffect(() => {
    if (props.done) {
      setColor("euraturquoise");
      setColorBorder("euraturquoise");
    } else {
      setColor("eurablue");
      setColorBorder("white");
    }
  }, [props.done]);

  return (
    <div>
      <div className="relative flex items-center m-auto">
        <button
          className={
            "flex items-center rounded-full w-11 h-11 " +
            (props.step < props.number
              ? "bg-eurahidden border border-eurahidden"
              : "border border-" + color + " bg-" + colorBorder)
          }
          onClick={() => {
            if (props.step < props.number) return;
            props.setStep(props.number);
          }}
        >
          {props.step >= props.number && props.done && (
            <div className="absolute left-2.5 top-2.5 z-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99999 15.172L19.192 5.979L20.607 7.393L9.99999 18L3.63599 11.636L5.04999 10.222L9.99999 15.172Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
          <div
            className={
              "mx-auto rounded-full w-8 h-8 " +
              (props.step < props.number
                ? "bg-eurahidden border border-eurahidden "
                : "bg-" + color + " border border-" + color)
            }
          ></div>
        </button>
        <p className={"ml-2 text-euragreylight font-medium"}>{props.name}</p>
      </div>
      {!props.end && (
        <div
          className={
            "w-0.5 h-6 mr-auto ml-6.5 " +
            (props.step < props.number
              ? "bg-eurahidden"
              : props.done
              ? "bg-" + color
              : "bg-eurablue")
          }
        ></div>
      )}
    </div>
  );
}

export function Trail(props) {
  return (
    <div className="flex flex-col bg-white px-8 pb-12 rounded-xl items-center shadow shadow-lg">
      <div className="flex flex-col mt-6">
        <Round
          setStep={props.setStep}
          name="Choose file"
          number={0}
          step={props.step}
          done={props.chooseQuestionsDone}
        />
        <Round
          setStep={props.setStep}
          name="Select Q&A"
          number={1}
          step={props.step}
          done={props.questionsDone}
        />
        <Round
          setStep={props.setStep}
          name="Verify Questions"
          number={2}
          step={props.step}
          done={props.verifyDone}
        />
        <Round
          setStep={props.setStep}
          name="Choose Scope"
          number={3}
          step={props.step}
          done={props.chooseScopeDone}
        />
        <Round
          setStep={props.setStep}
          name="Run analytics"
          number={4}
          step={props.step}
          done={props.runAnalysisDone}
        />
        <Round
          setStep={props.setStep}
          name="View results"
          number={5}
          end="true"
          step={props.step}
          done={props.chooseScopeDone}
        />
      </div>
    </div>
  );
}
