import React, { useEffect, useState } from "react";
import HistoryTable from "../components/HistoryTable";
import { Menu } from "../components/Menu";

const HistoryPage = (props) => {
  const columns = [
    {
      Header: "File Name",
      accessor: "sheet_name",
    },
    {
      Header: "Date",
      accessor: "timestamp",
    },
    {
      Header: "Action",
      Cell: (row) => {
        return (
          <button
            className="text-eurablue"
            onClick={() => {
              window.open(
                process.env.REACT_APP_API_URL +
                  "/download/" +
                  row.row.original.token
              );
            }}
          >
            Download
          </button>
        );
      },
    },
  ];

  if (props.executions.length === 0)
    return (
      <div className="flex flex-col h-screen">
        <Menu />
      </div>
    );

  return (
    <div className="flex flex-col h-screen sm:w-2/3 xl:w-1/2 mx-auto mt-22">
      <Menu current={props.current} setCurrent={props.setCurrent} />
      <div className="flex flex-col bg-gray flex-grow w-full mx-auto mt-22">
        <HistoryTable
          type="files uploaded"
          columns={columns}
          data={props.executions.map((execution) => {
            //convert timestamp to readable format
            execution.timestamp = new Date(
              execution.timestamp
            ).toLocaleString();
            return execution;
          })}
        />
      </div>
    </div>
  );
};

export default HistoryPage;
