import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export function Menu(props) {
  const history = useHistory();

  return (
    <div className="flex position: fixed right-0 top-0  w-full bg-euramenu items-center z-50">
      <button
        className="ml-6 my-auto"
        onClick={() => {
          //redirect to home page
          history.push("/step/1");
          window.location.reload();
        }}
      >
        <img src="../logo.svg" alt="logo" className="h-16 m-auto" width="130" />
      </button>
      {/* 
      <button
        className={
          "my-auto ml-16 " +
          (props.current === "upload" ? "text-white" : "text-white")
        }
        onClick={() => {
          //redirect to upload page
          history.push("/upload");
          props.setCurrent("upload");
        }}
      >
        Upload
      </button>
      <button
        className={
          "my-auto ml-16 " +
          (props.current === "history" ? "text-white" : "text-white")
        }
        onClick={() => {
          //redirect to history page
          history.push("/history");

          props.setCurrent("history");
        }}
      >
        History
      </button>
      */}
      <div className="my-auto ml-auto mr-16"></div>
      {props.user &&
        props.user[
          "http://schemas.microsoft.com/identity/claims/displayname"
        ] && (
          <p className="text-white mr-10 text-lg font-regular m-auto">
            {
              props.user[
                "http://schemas.microsoft.com/identity/claims/displayname"
              ]
            }
          </p>
        )}
      <button>
        <img src="../profile.svg" alt="user" className="h-12 ml-auto mr-22" />
      </button>
    </div>
  );
}
