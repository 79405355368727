import Select from "react-select";
import { useEffect } from "react";
import AutoCompleteInput from "./AutoCompleteInput";
import Grid from "./DataGrid";
import FileUploadComponent from "./FileUploadComponent";
import MyDropzone from "./MyDropzone";
import { Trail } from "./Trail";
import { useState } from "react";
import { Validate } from "./Validate";
import QuestionsTable from "./QuestionsTable";
import { ScopeSelector } from "./ScopeSelector";

export const StepComponent = (props) => {
  var steps_description = [
    "Please select the survey file to start the analysing process",
    "Please select the tab you want to analyse and specify where is located questions & answers",
    "",
    "Please select if you want to run analyse on companies or funds.",
    "The file is being processing by the ESG Engine. Please wait during the analysis",
    "Please see below the results",
    "Please see below the results",
  ];

  return (
    <div
      className={
        "flex bg-white rounded-xl shadow shadow-lg " +
        (props.step === 0 ? "h-full" : "h-5/6")
      }
    >
      <div className="flex flex-col w-full h-full">
        {props.step !== 2 && (
          <p
            className={"text-euragreylight mt-8 font-medium ml-3.5 text-lg p-4"}
          >
            {steps_description[props.step]}
          </p>
        )}
        {props.step === 2 && (
          <div className="flex flex-grow mx-auto w-full h-full">
            <div className="flex flex-col w-full h-full">
              <QuestionsTable
                questions={props.questions.filter(
                  (question) => question.text !== ""
                )}
                setQuestions={props.setQuestions}
              />
            </div>
          </div>
        )}
        {props.step === 0 && (
          <MyDropzone
            data={props.data}
            setData={props.setData}
            hidden={props.hidden}
            setStep={props.setStep}
            setUploadedData={props.setUploadedData}
            setSheets={props.setSheets}
            setFile={props.setFile}
            setChooseQuestionsDone={props.setChooseQuestionsDone}
          />
        )}
        {(props.step === 1 || props.step >= 3) && (
          <div
            className={
              "w-full flex mx-auto items-center " +
              (props.step !== 3 && props.step !== 4 ? "h-full" : "")
            }
          >
            {(props.step === 1 || props.step >= 5) && (
              <Grid
                data={props.data}
                setHidden={props.setHidden}
                columns={props.columns}
                rows={props.rows}
                setColumns={props.setColumns}
                setRows={props.setRows}
                setData={props.setData}
                step={props.step}
                invertRows={props.invertRows}
                setInvertRows={props.setInvertRows}
                setAnswerLabel={props.setAnswerLabel}
                setQuestionLabel={props.setQuestionLabel}
                uploadedData={props.uploadedData}
                setSelectedSheet={props.setSelectedSheet}
                selectedSheet={props.selectedSheet}
                sheets={props.sheets}
                setQuestionColumn={props.setQuestionColumn}
                questionColumn={props.questionColumn}
                setQuestions={props.setQuestions}
                setStep={props.setStep}
                questionLabel={props.questionLabel}
                answerLabel={props.answerLabel}
                setAnswerColumn={props.setAnswerColumn}
                answerColumn={props.answerColumn}
                setNextStepAvailable={props.setNextStepAvailable}
                nbTokens={props.nbTokens}
                nbQuestionsProcessed={props.nbQuestionsProcessed}
                setNbQuestionsProcessed={props.setNbQuestionsProcessed}
                executionTime={props.executionTime}
                setExecutionTime={props.setExecutionTime}
                companiesSelected={props.companiesSelected}
                fundsSelected={props.fundsSelected}
                scope={props.scope}
                questions={props.questions}
              />
            )}
            {props.step >= 3 && (
              <div className="mx-auto flex items-center">
                <div className="flex flex-col">
                  <ScopeSelector
                    scope={props.scope}
                    setScope={props.setScope}
                    step={props.step}
                    companies={props.companies}
                    setCompaniesSelected={props.setCompaniesSelected}
                    funds={props.funds}
                    setFundsSelected={props.setFundsSelected}
                    fundsSelected={props.fundsSelected}
                    companiesSelected={props.companiesSelected}
                  />
                  <FileUploadComponent
                    setHidden={props.setHidden}
                    setStep={props.setStep}
                    step={props.step}
                    file={props.file}
                    questions={props.questions}
                    answerColumn={props.answerColumn}
                    token={props.token}
                    setToken={props.setToken}
                    setFile={props.setFile}
                    setData={props.setData}
                    setSheets={props.setSheets}
                    setUploadedData={props.setUploadedData}
                    companiesSelected={props.companiesSelected}
                    fundsSelected={props.fundsSelected}
                    sendProcess={props.sendProcess}
                    processSent={props.processSent}
                    setProcessSent={props.setProcessSent}
                    selectedSheet={props.selectedSheet}
                    invertRows={props.invertRows}
                    setNextStepAvailable={props.setNextStepAvailable}
                    uploadProgress={props.uploadProgress}
                    estimatedTime={props.estimatedTime}
                    setEstimatedTime={props.setEstimatedTime}
                    setUploadProgress={props.setUploadProgress}
                    setNbTokens={props.setNbTokens}
                    nbTokens={props.nbTokens}
                    nbQuestionsProcessed={props.nbQuestionsProcessed}
                    setNbQuestionsProcessed={props.setNbQuestionsProcessed}
                    executionTime={props.executionTime}
                    setExecutionTime={props.setExecutionTime}
                    scope={props.scope}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
