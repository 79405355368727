import AutoCompleteInput from "./AutoCompleteInput";
import { ScopeSelectorCompanies } from "./ScopeSelectorCompanies";
import { ScopeSelectorFunds } from "./ScopeSelectorFunds";

export function ScopeSelector(props) {
  if (props.step !== 3) return null;
  return (
    <div className="flex flex-col">
      <div className="flex bg-euragrey rounded-md w-72 mx-auto p-2">
        <button
          className={
            "flex w-1/2 m-auto p-6 rounded-md " +
            (props.scope === "Companies" ? "bg-white shadow shadow-sm" : "")
          }
          onClick={() => {
            props.setScope("Companies");
            props.setCompaniesSelected([]);
            props.setFundsSelected([]);
          }}
        >
          <p className="m-auto font-semibold">Companies</p>
        </button>
        <button
          className={
            "flex w-1/2 m-auto rounded-md p-6 " +
            (props.scope === "Funds" ? "bg-white shadow shadow-sm" : "")
          }
          onClick={() => {
            props.setScope("Funds");
            props.setCompaniesSelected([]);
            props.setFundsSelected([]);
          }}
        >
          <p className="m-auto font-semibold">Funds</p>
        </button>
      </div>
      <div className="flex mx-auto mt-22">
        <div>
          {props.scope === "Companies" && (
            <ScopeSelectorCompanies
              companies={props.companies}
              setCompaniesSelected={props.setCompaniesSelected}
              funds={props.funds}
              setFundsSelected={props.setFundsSelected}
              fundsSelected={props.fundsSelected}
              companiesSelected={props.companiesSelected}
            />
          )}
          {props.scope === "Funds" && (
            <ScopeSelectorFunds
              funds={props.funds}
              setFundsSelected={props.setFundsSelected}
              fundsSelected={props.fundsSelected}
            />
          )}
        </div>
      </div>
    </div>
  );
}
