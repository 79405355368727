import AutoCompleteInput from "./AutoCompleteInput";

export function ScopeSelectorFunds(props) {
  return (
    <div className="flex flex-col">
      <p className="text-euratable font-normal">Selected Funds</p>
      <AutoCompleteInput
        data={props.funds.map((fund) => {
          return { title: fund.fundName, short_name: fund.shortName };
        })}
        setSelected={props.setFundsSelected}
        value={props.fundsSelected}
      />
    </div>
  );
}
